<template>
  <vx-card title="Customer Update">
    <vs-button
      class="mt-2"
      color="success"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    >
    <br />
    <div clss="vx-row">
      <data-table :baseUrl="this.baseUrl"></data-table>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-update",
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "customer-update-create",
        // params: { id: id },
      });
    },
  },
  mounted() {},
  watch: {},
};
</script>